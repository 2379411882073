<template>
  <div class="get_m_a_s">

    <div class="filtrate menu-x" style="border-top: none">
      <div class="header-title">
        <p>报表类型</p>
      </div>
      <ul>
        <li v-for="(item, index) in menuList" :key="item" :class="activeIndex === index && 'active'"
            @click="changeActive(index)">{{item}}</li>
      </ul>
    </div>

    <!-- 筛选 -->
    <div class="filtrate">
      <div class="header-title">
        <p>筛选</p>
      </div>
      <div v-if="activeIndex < 2" class="header-from">
        <el-form
            :inline="true"
            :model="queryData"
            class="ylsdyrmyy-form-inline"
            size="mini"
            ref="queryData"
        >
          <el-form-item label="测评量表" prop="id">
            <el-select v-model="table1SelectBefore.lbId" filterable>
              <el-option
                  :label="item.measure_title"
                  :value="item.id"
                  v-for="(item, key) in scaleList"
                  :key="key"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="医院" prop="department_id">
            <el-select multiple collapse-tags
                       v-model="table1SelectBefore.hospotitalId" filterable>
              <el-option
                  v-for="item in departmentList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
              >{{ item.name }}</el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="时间范围" prop="date">
            <el-date-picker
                v-model="date1[0]"
                type="date"
                placeholder="开始日期"
                value-format="timestamp"
            >
            </el-date-picker>
            <span style="padding: 0 16px">至</span>
            <!-- 结束 -->
            <el-date-picker
                v-model="date1[1]"
                type="date"
                placeholder="结束日期"
                value-format="timestamp"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button plain @click="getTable(1)">查询</el-button>
            <el-button @click="resetForm(1)">重置</el-button>
            <el-button @click="exportExcel">导出表格</el-button>
          </el-form-item>
        </el-form>
      </div>

      <div v-else class="header-from">
        <el-form
            :inline="true"
            :model="queryData"
            class="ylsdyrmyy-form-inline"
            size="mini"
            ref="queryData"
        >
          <el-form-item label="测评量表" prop="id">
            <el-select v-model="table2SelectBefore.lbId" filterable>
              <el-option
                  :label="item.measure_title"
                  :value="item.id"
                  v-for="(item, key) in scaleList"
                  :key="key"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="医院" prop="department_id">
            <el-select multiple collapse-tags
                       v-model="table2SelectBefore.hospotitalId" filterable>
              <el-option
                  v-for="item in departmentList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
              >{{ item.name }}</el-option
              >
            </el-select>
          </el-form-item>

          <el-form-item label="时间范围" prop="date">
            <el-date-picker
                v-model="date2[0]"
                type="date"
                placeholder="开始日期"
                value-format="timestamp"
            >
            </el-date-picker>
            <span style="padding: 0 16px">至</span>
            <!-- 结束 -->
            <el-date-picker
                v-model="date2[1]"
                type="date"
                placeholder="结束日期"
                value-format="timestamp"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button plain @click="getTable(2)">查询</el-button>
            <el-button @click="resetForm(2)">重置</el-button>
            <el-button @click="exportImage">保存图表</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>


    <div class="filtrate menu-x">
      <div class="header-title">
        <p>参数</p>
      </div>
      <div style="display: flex;align-items: center">
        <template v-if="activeIndex === 0">
          <el-checkbox-group style="margin-left: 20px" v-model="checkList1" @change="handleSelectChange">
            <el-checkbox v-for="item in columnData1" :key="item.type" :label="item.type">{{item.name}}</el-checkbox>
          </el-checkbox-group>
        </template>

        <template v-else-if="activeIndex === 1">
          <el-checkbox-group style="margin-left: 20px" v-model="checkList2" @change="handleSelectChange2">
            <el-checkbox v-for="item in columnData2" :key="item.type" :label="item.type">{{item.name}}</el-checkbox>
          </el-checkbox-group>
        </template>

        <template v-else-if="activeIndex === 2">
          <el-radio-group style="margin-left: 20px" v-model="checkValue" @input="handleCheckValueChange">
            <el-radio v-for="item in columnData3" :key="item.type" :label="item.type">{{item.name}}</el-radio>
          </el-radio-group>
        </template>
      </div>
    </div>

    <!--    代动画的三个图例 -->
    <transition name="fade-transform" mode="out-in">
      <!-- 表格 -->
      <div class="content" v-if="activeIndex === 0" key="a0zx">
        <el-table :data="tableData1" id="table-yytj" style="width: 100%">
          <el-table-column label="医院名称" prop="hospital_name" align="center" />
          <template  v-for="item in columnData1">
            <el-table-column v-if="item.show" :label="item.name" :prop="item.type" align="center" />
          </template>
        </el-table>

      </div>


      <!-- 表格2 -->
      <div class="content" v-else-if="activeIndex === 1" key="a1zx">
        <el-table :data="tableData2" id="table-yytj1" style="width: 100%">
          <el-table-column label="医院名称" prop="hospital_name" align="center" />
          <template v-for="item in columnData2">
            <el-table-column v-if="item.show" :label="item.name" :prop="item.type" align="center" />
          </template>
        </el-table>
      </div>

      <!-- 表格3 -->
      <div class="content" v-else-if="activeIndex === 2" key="a2zx">
        <div v-if="isTable3" id="zx-chart" style="width: 100%;height: 60vh"></div>
        <div v-else style="width: 100%;height: 60vh;display: flex;align-items: center;justify-content: center">
          抱歉，所选条件没有数据~
        </div>
      </div>
    </transition>


    <!--       选择信息  -->
    <el-dialog
        title="请选择"
        :visible.sync="showYy"
        width="30%"
        center
        :close-on-click-modal="false"
    >
      <el-form :model="table1SelectBefore" label-width="120px">
        <el-form-item label="测评量表" required>
          <el-select style="width: 100%" v-model="table1SelectBefore.lbId" placeholder="必须选择">
            <el-option
                :label="item.measure_title"
                :value="item.id"
                v-for="(item, key) in scaleList"
                :key="key"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="医院（多选）" required>
          <el-select style="width: 100%" multiple collapse-tags
                     v-model="table1SelectBefore.hospotitalId" placeholder="必须选择">
            <el-option
                :label="item.name"
                :value="item.id"
                v-for="(item, key) in departmentList"
                :key="key"
            ></el-option>
          </el-select>
        </el-form-item>


        <el-form-item label="时间" required>
          <el-date-picker
              v-model="date1[0]"
              type="date"
              placeholder="开始日期"
              value-format="timestamp"
          >
          </el-date-picker>
          <span style="padding: 0 16px">至</span>
          <!-- 结束 -->
          <el-date-picker
              v-model="date1[1]"
              type="date"
              placeholder="结束日期"
              value-format="timestamp"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleTable1SelectBefore">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {getAllDepartment, getMeasureByClassify} from "@/api/evaluate.js";
import * as echarts from "echarts"
import FileSaver from "file-saver";
import XLSX from "xlsx";
import color16 from "../../../utils/color"
import { loadDepartmentData } from "@/api/comm.js";
import { get_measure_user_statistics } from "@/api/user_info_statistical.js";
import { loadReportStatistical, loadReportStatistical2 } from "@/api/report"
import axios from "axios";

export default {
  data() {
    return {
      isTable3: false,
      menuList: ['医院统计', '量表统计', '趋势图'],
      checkList1: ['report', 'inspect', 'inspect%', 'time', 'warn%'],
      checkList2: ['scl','yxl'],
      checkValue: 1,
      activeIndex: 0,
      scaleData: [],
      departmentList: [],
      formInline: {},
      scaleList: [],
      tableData1: [],
      tableData2: [],
      table1SelectBefore: {
        lbId: 68,
        hospotitalId: []
      },
      table2SelectBefore: {
        lbId: 68,
        hospotitalId: []
      },
      columnData1: [
        {
          name: "筛查量",
          type: "report",
          show: true
        },
        {
          name: "阳性人数",
          type: "inspect",
          show: true
        },
        {
          name: "阳性率",
          type: "inspect%",
          show: true
        },
        {
          name: "审核平均时长",
          type: "time",
          show: true
        },
        {
          name: "审核达标率",
          type: "warn%",
          show: true
        },
      ],
      columnData2: [],
      columnData3: [
        {
          name: "日",
          type: 1
        },
        {
          name: "月",
          type: 2
        },
        {
          name: "季",
          type: 3
        },
        {
          name: "年",
          type: 4
        }
      ],
      date1: [],
      date2: [],
      date3: [],
      queryData: {
        did: "",
        min: "",
        stime: "",
        etime: "",
      },
      myChart: null,
      showYy: false
    };
  },
  created() {
    // 获取量表列表
    this.getAllDepartmentFn()
    this.getMeasureByClassifyFn()
  },
  mounted() {
    this.showYy = !(this.table1SelectBefore.lbId && this.table1SelectBefore.hospotitalId && (this.date1[0] && this.date1[1]));
    window.onresize = () => {
      this.myChart && this.myChart.resize()
    }
  },
  methods: {
    handleTable1SelectBefore() {
      if(this.table1SelectBefore.lbId && this.table1SelectBefore.hospotitalId && (this.date1[0] && this.date1[1])) {
        this.loadData()
        this.showYy = false
      } else{
        this.$message.error("请选择完整！")
      }
    },
    handleCheckValueChange() {
      this.loadData2()
    },
    exportImage() {
      const imageUrl = this.myChart.getDataURL({
        type: 'png',
        pixelRatio: 1.5,
        backgroundColor: '#fff',
      })
      const elink = document.createElement('a');
      elink.download = "统计数据分析" + '-' + this.columnData3[this.checkValue-1].name + '趋势统计图' + new Date().getTime() + '.png';
      elink.style.display = 'none';
      elink.href = imageUrl;
      document.body.appendChild(elink);
      elink.click();
      URL.revokeObjectURL(elink.href); // 释放URL 对象
      document.body.removeChild(elink)
    },
    exportExcel() {
      const xlsxName = "报表统计-" + (this.menuList[this.activeIndex]) + "-" + new Date().getTime() + '.xlsx'
      const wb = XLSX.utils.table_to_book(document.querySelector("#table-yytj"));
      const wbout = XLSX.write(wb, {
        bookType: "xlsx",
        bookSST: true,
        type: "array"
      });
      try {
        FileSaver.saveAs(new Blob([wbout], { type: "application/octet-stream" }), xlsxName);
      } catch (e) {
        if (typeof console !== "undefined") console.log(e, wbout);
        this.$message({
          message: '保存失败！',
          type: 'error'
        });
      }
      return wbout;
    },
    async changeActive(i) {
      if(this.activeIndex !== i) {
        this.activeIndex = i

        if(this.activeIndex === 2) {
          this.table2SelectBefore.lbId = this.table1SelectBefore.lbId
          this.table2SelectBefore.hospotitalId = this.table1SelectBefore.hospotitalId
          this.date2 = this.date1
          await this.loadData2()
        } else {
          await this.loadData()
        }
      }
    },
    async loadData() {
      const data = {
        hospital_ids: this.table1SelectBefore.hospotitalId.join(','),
        measure_id: this.table1SelectBefore.lbId,
        time_start: Math.floor(this.date1[0] / 1000) + '',
        time_end: Math.floor(this.date1[1] / 1000) + '',
      }
      // 对应 图1 图2
      const res = await loadReportStatistical(data)
      if(res.status === 200) {
        const data = res.data
        // 因子对应中文
        const factor = data.factor
        for(let key in factor) {
          this.columnData2.push({
            name: factor[key],
            type: key,
            show: true
          })
        }
        const hospitals = data.hospital
        this.tableData1 = []
        this.tableData2 = []
        for(let key in hospitals) {
          let dataLine1 = {}
          dataLine1['hospital_name'] = hospitals[key]
          this.tableData1.push({...dataLine1, ...data.table1[key]})
          this.tableData2.push({...dataLine1, ...data.table2[key]})
        }
      } else {
        this.$message.error(res.statusText || "服务器错误")
      }
    },
    async loadData2() {
      const data = {
        hospital_ids: [this.table2SelectBefore.hospotitalId].join(','),
        measure_id: this.table2SelectBefore.lbId,
        type: this.checkValue,
        time_start: Math.floor(this.date2[0] / 1000) + '',
        time_end: Math.floor(this.date2[1] / 1000) + '',
      }
      const res = await loadReportStatistical2(data)
      if(res.status === 200) {
        // 存在数据则渲染
        const data = res.data
        let od = {}
        if(Object.keys(data.table3).length > 0 || data.table3.length > 0) {
          let yxHospital = []
          let ses = []
          let st = new Set()
          for(let key in data.table3) {
            yxHospital.push(data.hospital[key])
            const dd = data.table3[key]
            Object.keys(dd).forEach(key => {
              st.add(key)
            })
            const rColor = color16()
            ses.push({
              name: data.hospital[key],
              type: 'line',
              stack: '总量',
              itemStyle: {
                normal: {
                  color: rColor
                }
              },
              lineStyle: {
                color: rColor
              },
              data: Object.values(dd)
            })
          }
          od.title = {
            text: this.columnData3[this.checkValue-1].name + '统计趋势图'
          }
          od.legend = {
            data: yxHospital
          }
          od.xAxis = {
            data: [...st]
          }
          od.series = ses
          this.isTable3 = true
          this.$nextTick(() => {
            setTimeout(() => {
              this.initChart(od)
            }, 300)
          })
        } else {
          this.isTable3 = false
        }
      } else {
        this.$message.error(res.statusText || "服务器错误")
      }
    },
    async getAllDepartmentFn() {
      getAllDepartment().then(res => {
        if(res.code === 400200) {
          this.departmentList = res.data.parents
        }
      })
    },
    async getMeasureByClassifyFn() {
      getMeasureByClassify().then(res => {
        if(res.code === 400200) {
          res.data.forEach((v) => {
            v.status_measure.forEach((v) => {
              this.scaleList.push(v);
            });
          });
        }
      })
    },
    //
    getTable(ix) {
      if(ix === 1) {
        this.loadData()
      } else {
        this.loadData2()
      }
    },
    getchartData() {},
    handleSelectChange(e) {
      const oldData = this.columnData1.map(item => {
        item.show = false
        return item
      })
      e.forEach(i => {
        const ix = oldData.findIndex(item => item.type === i)
        oldData[ix].show = true
      })
    },
    handleSelectChange2(e) {
      console.log(e)
    },
    resetForm(ix) {
      if(ix === 1) {
        this.table1SelectBefore.lbId = null
        this.table1SelectBefore.hospotitalId = null
        this.date1.length = 0
      } else {
        this.table2SelectBefore.lbId = null
        this.table2SelectBefore.hospotitalId = null
        this.date2.length = 0
      }
    },
    initChart(data) {
      const optionOld = {
        tooltip: {
          trigger: 'axis'
        },
        legend: {},
        grid: {
          left: '0%',
          right: '4%',
          bottom: '3%',
          top: '10%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
        },
        yAxis: {
          type: 'value'
        },
      }
      this.myChart = echarts.init(document.getElementById("zx-chart"))
      this.myChart.setOption({...optionOld, ...data})
    }
  },
};
</script>
<style lang="less" scoped>
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}

.get_m_a_s {
  width: 100%;
  height: calc(~"100% - 126px");
  padding-bottom: 10px;

  .el-date-editor.el-input, .el-date-editor.el-input__inner{
    width: 140px;
  }

  // 筛选
  .filtrate {
    display: flex;
    width: 100%;
    height: 50px;
    background: rgb(217, 242, 247);
    border-top: 1px solid rgb(127, 222, 243);
    .header-title {
      color: rgb(4, 87, 102);
      width: fit-content;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 12px;
      border-right: 1px solid rgb(127, 222, 243);
    }
    p {
      line-height: 50px;
      font-size: 14px;
    }
    .header-from {
      padding-left: 10px;
      display: flex;
      align-items: center;
      .el-input__inner {
        width: 120px;
        padding-right: 20px;
      }
      .el-form-item:nth-child(3) .el-input__inner {
        // width: 220px;
        padding-right: 0;
      }
      .el-form-item {
        margin-bottom: 0;
      }
      .el-form-item__label {
        color: rgb(4, 87, 102);
      }
      .el-input__icon {
        width: 15px;
      }
      .el-button--mini {
        padding: 5px 10px;
        background: transparent;
        color: rgb(4, 87, 102);
        font-size: 16px;
        &:hover {
          border: 1px solid rgb(105, 105, 105);
        }
      }
    }

    /deep/ .el-input__inner {
      height: 28px !important;
      line-height: 28px !important;
      overflow: hidden;
    }

    /deep/ .el-select__tags {
      max-width: 300px !important;
    }

    /deep/ .el-select__input.is-mini {
      height: 0 !important;
    }
  }

  .menu-x {
    ul {
      margin-left: 20px;
      display: flex;
      align-items: center;
      li {
        list-style: none;
        padding: 12px;
        margin-right: 20px;
        font-size: 15px;
        color: #777;
        cursor: pointer;
        &:hover {
          color: #045766;
        }
        &.active {
          color: #045766;
          position: relative;
          &::after {
            content: "";
            position: absolute;
            width: 100%;
            height: 2px;
            bottom: 1px;
            left: 50%;
            border-radius: 2px;
            transform: translateX(-50%);
            background-color: #045766;
          }
        }
      }
    }
  }

  .content {
    position: relative;
    height: calc(~"100% - 220px");
    min-height: 745px;
    margin-top: 20px;
    color: rgb(4, 87, 102);
    th {
      color: rgb(4, 87, 102);
      padding: 0;
      height: 40px;
      line-height: 40px;
      background: rgb(217, 242, 247);
    }
    td {
      padding: 0;
      height: 35px;
      line-height: 35px;
    }
    tr.el-table__row--striped td {
      background: rgb(234, 246, 248);
    }
    .el-button + .el-button {
      margin-left: 0px;
    }
    .el-button--mini {
      padding: 4px 4px;
      background: transparent;
      color: rgb(4, 87, 102);
      font-size: 14px;
      &:hover {
        border: 1px solid rgb(105, 105, 105);
      }
    }
    .no {
      color: green;
    }
    .off {
      color: red;
    }
  }


  .el-table__footer-wrapper {
    .el-table__footer {
      .has-gutter {
        tr {
          td {
            background: rgb(252, 218, 28);
            color: #3c3c3c;
            font-size: 16px;
            font-weight: 700;
          }
        }
      }
    }
  }

}

</style>
